import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/Admin-Logo.png";
import './Sidebar.css';

import { SidebarData } from "../../Data/Data";
import { UilSignOutAlt } from "@iconscout/react-unicons";


function handleLogout() {
    localStorage.clear();
    console.log("User logged out");
}


const Sidebar = () => {

    const [selected, setSelected] = useState(0)

    return (
        <div className="Sidebar">
            {/* logo */}
            <div className="logo">
                <img src={Logo} alt="" />
            </div>

            {/* menu */}
            <div className="menu">
                {SidebarData.map((item, index) => {
                    return (
                        <Link
                            to={item.linkTo}
                            key={index}
                            className={selected === index ? 'menuItem active' : 'menuItem'}
                        >
                            <item.icon />
                            <button onClick={() => setSelected(index)}>{item.heading}</button>
                        </Link>
                    )
                })};

                <div className="menuItem" onClick={handleLogout}>
                    <UilSignOutAlt />
                    <span>
                        <h5>LOgOut</h5>
                    </span>
                </div>
            </div>
        </div>
    )
}

export default Sidebar
