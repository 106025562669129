import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './login.css';

const Login = ({ onLogin }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigatTo = useNavigate();

    const handleEmailChange = (e) => setEmail(e.target.value);
    const handlePasswordChange = (e) => setPassword(e.target.value);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://mavenfleet.herokuapp.com/api/v1/admin/login', { email, password });
            console.log(response.data.status)

            if (response.data.status === 'success') {
                alert('Loggedin successfully');
                const { token } = response.data;
                localStorage.setItem('token', token)
                onLogin();
                navigatTo('/admin-dashboard');
            }
        } catch (error) {
            alert(error);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="card" >
            <label>
                Email:
                <input type="email" value={email} onChange={handleEmailChange} />
            </label>
            <label>
                Password:
                <input type="password" value={password} onChange={handlePasswordChange} />
            </label>
            <button type="submit">Login</button>
        </form>
    );
};

export default Login;
