// Sidebar imports
import {
    UilEstate,
    UilUsersAlt,
    UilChart
} from "@iconscout/react-unicons";

// Sidebar Data
export const SidebarData = [
    {
        icon: UilEstate,
        heading: "Dashboard",
        linkTo: "/admin-dashboard"
    },
    {
        icon: UilUsersAlt,
        heading: "Visitors",
        linkTo: "/users"
    },
    {
        icon: UilChart,
        heading: "Analytics",
        linkTo: "https://analytics.google.com/analytics/web/#/p356554278/reports/intelligenthome"
    }
];

export const cardsData = [
    {
        id: "1",
        title: "Joiners",
        color: {
            backGround: "#cf95a2",
            boxShadow: "0px 10px 20px 0px #685d5f",
        },
        barValue: 40,
        value: "15",
        series: [
            {
                name: "Sales",
                data: [31, 40, 28, 51, 42, 109, 100],
            },
        ],
    },
    {
        id: "2",
        title: "Partnerships",
        color: {
            backGround: "#cf95a2",
            boxShadow: "0px 10px 20px 0px #685d5f",
        },
        barValue: 40,
        value: "14",
        series: [
            {
                name: "Revenue",
                data: [10, 100, 50, 70, 80, 30, 40],
            },
        ],
    },
    {
        id: "3",
        title: "Stay in touch",
        color: {
            backGround:
                "#cf95a2",
            boxShadow: "0px 10px 20px 0px #685d5f",
        },
        barValue: 20,
        value: "7",
        series: [
            {
                name: "Expenses",
                data: [10, 25, 15, 30, 12, 15, 20],
            },
        ],
    },
];
