import '../Admin.css'
import Sidebar from '../components/Sidebar/Sidebar';
import UsersList from './users';

function Visitors() {

    return (
        <div className="App">
            <div className='AppGlass-left'>
                <Sidebar />
            </div>
            <div className="AppGlass">
                <UsersList />
            </div>
        </div>

    );

}

export default Visitors;
